import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";

export default function Testimonial() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-9739e31 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="9739e31"
      data-element_type="section"
    >
      <div className="elementor-background-overlay"></div>
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-30f1dd5"
          data-id="30f1dd5"
          data-element_type="column"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-d17a9f1 elementor-invisible elementor-widget elementor-widget-heading"
              data-id="d17a9f1"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInUp"}'
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h5 className="elementor-heading-title elementor-size-default">
                  Client feedback
                </h5>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-07f7962 elementor-widget elementor-widget-heading"
              data-id="07f7962"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  don’t just take our word for it
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-94af184"
          data-id="94af184"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
            <div
              className="elementor-element elementor-element-1b52af5 elementor-view-default elementor-widget elementor-widget-icon"
              data-id="1b52af5"
              data-element_type="widget"
              data-widget_type="icon.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-icon-wrapper">
                  <div className="elementor-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="a01944b3-882c-4616-aa63-a98050fb2a66"
                      data-name="Layer 1"
                      width="1.9329cm"
                      height="1.8407cm"
                      viewBox="0 0 54.7899 52.1785"
                    >
                      <polygon
                        points="26.443 0 0 51.096 1.681 52.179 28.124 1.083 26.443 0"
                        style={{ fillRule: "evenodd" }}
                      ></polygon>
                      <polygon
                        points="53.109 0 26.666 51.096 28.347 52.179 54.79 1.083 53.109 0"
                        style={{ fillRule: "evenodd" }}
                      ></polygon>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="elementor-element elementor-element-bf19b87 elementor-testimonial--align-left elementor-testimonial--tablet-align-center elementor-testimonial--skin-default elementor-testimonial--layout-image_inline elementor-widget elementor-widget-testimonial-carousel e-widget-swiper"
              data-id="bf19b87"
            >
              <div className="elementor-widget-container">
                <div className="elementor-swiper">
                  <div
                    className="elementor-main-swiper swiper-container swiper-container-initialized swiper-container-horizontal"
                    style={{ cursor: "grab" }}
                  >
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                      autoplay={{
                        delay: "3000",
                      }}
                      scrollbar={false}
                      pagination={false}
                      modules={[Autoplay, EffectFade]}
                    >
                      <SwiperSlide>
                        <div className="elementor-testimonial">
                          <div className="elementor-testimonial__content">
                            <div className="elementor-testimonial__text">
                              IS Auditr is an excellent organisation with a
                              great understanding of ISO 9001 standards. They
                              helped my company with getting the ISO 9001
                              certification. They managed the entire process
                              from documentation to audit promptly. They also
                              acted as our representatives for the Certification
                              body and helped us achieve certification in a few
                              weeks. We will use their services on annual basis
                              for our renewal. Highly recommended.
                            </div>
                          </div>
                          <div className="elementor-testimonial__footer">
                            <cite className="elementor-testimonial__cite">
                              <span className="elementor-testimonial__name">
                                Amol Solanke, Director
                              </span>
                              <span className="elementor-testimonial__title">
                                Leo Medica
                              </span>
                            </cite>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="elementor-testimonial">
                          <div className="elementor-testimonial__content">
                            <div className="elementor-testimonial__text">
                              IS Auditr did a great job in reviewing all our
                              manuals and help us prepare for our upcoming
                              audit. Great work!
                            </div>
                          </div>
                          <div className="elementor-testimonial__footer">
                            <cite className="elementor-testimonial__cite">
                              <span className="elementor-testimonial__name">
                                Hameedullah Khan, CEO
                              </span>
                              <span className="elementor-testimonial__title">
                                SUDO Consultants
                              </span>
                            </cite>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="elementor-testimonial">
                          <div className="elementor-testimonial__content">
                            <div className="elementor-testimonial__text">
                              Excellent and were very supportive of all changes
                              and modifications.
                            </div>
                          </div>
                          <div className="elementor-testimonial__footer">
                            <cite className="elementor-testimonial__cite">
                              <span className="elementor-testimonial__name">
                                Rashid Manoly, Quality Manager
                              </span>
                              <span className="elementor-testimonial__title">
                                LS Electric
                              </span>
                            </cite>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="elementor-testimonial">
                          <div className="elementor-testimonial__content">
                            <div className="elementor-testimonial__text">
                              IS Auditr jumped in to help on short notice. They
                              were committed to the success of the project. They
                              were very sincere and knowledgeable about ISO
                              27001 - a true treasure for the community!
                            </div>
                          </div>
                          <div className="elementor-testimonial__footer">
                            <cite className="elementor-testimonial__cite">
                              <span className="elementor-testimonial__name">
                                Shamik Basu, CISO
                              </span>
                              <span className="elementor-testimonial__title">
                                Agile Point
                              </span>
                            </cite>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
